import React, { useState } from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../../layouts'
import WithLocation from '../../components/shared/WithLocation'
import Rating from 'react-rating'
import { Buffer } from 'buffer'
import { values } from 'lodash'

import FullStar from '../../images/svgs/star-full.svg'
import EmptyStar from '../../images/svgs/star-empty.svg'


const sixtobin = function (b64Encoded) {
  return new Buffer(b64Encoded, 'base64').toString('binary');
};

const Review = (props) => {
  const { data } = props

  const displayedRecipes = data.allContentfulRecipe.nodes.filter((recipe) => {
    return props.search.recipes && props.search.recipes.split(",").indexOf(recipe.slug) != -1
  })

  const [submitting, setSubmitting] = useState(false)
  const [reviews, setReviews] = useState(displayedRecipes.reduce((acc, recipe) => {
    acc[recipe.slug] = {
      rating: null,
      review: null,
      slug: recipe.slug,
      name: recipe.name
    }

    return acc
  }, {}))

  const setRating = (slug: String, key: String, value: any) => {
    reviews[slug][key] = value

    setReviews(reviews)
  }

  const orderId = props.search.orderId
  let email
  if (props.search.identifier) {
    email = sixtobin(props.search.identifier)
  }


  const submitReviews = async () => {
    setSubmitting(true)
    const mealData = values(reviews).filter((meal) => {
      return meal.rating || meal.review
    })

    const data = {
      meals: mealData,
      orderId: orderId,
      email: email
    }

    const response = await fetch("https://reviews.elmfoods.com/.netlify/functions/create-review", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    response.json()

    window.location = "/reviews/thank-you"
  }



  return (
    <IndexLayout>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <h2>Let us know how you liked your food</h2>
            <p>
              We'll use your feedback to come up with better suggestions for you going forward!
            </p>
          </div>
        </div>
        {displayedRecipes.map((recipe) => {
          return (
            <div className="row py-3 my-2 border" key={`recipe-${recipe.name}-review-container`}>
              <div className="col-12 col-md-5">
                <img className="img-fluid" src={recipe.rectangularImage.fluid.src} />
              </div>
              <div className="col-12 col-md-7 mt-2 mt-md-0">
                <h4>{recipe.name}</h4>
                <div className="star-ratings">
                  <Rating
                    emptySymbol={<EmptyStar className="icon w35p" />}
                    fullSymbol={<FullStar className="icon w35p" />}
                    fractions="2"
                    onChange={(value) => {
                      setRating(recipe.slug, 'rating', value)
                    }}
                  />
                </div>
                <textarea className="form-control form-control-sm mt-3" rows="6" placeholder="Enter any comments here (optional)" onChange={(event) => {
                  const value = event.target.value
                  setRating(recipe.slug, 'review', value)
                }} />
              </div>
            </div>
          )
        })}

        {!submitting && <button className="btn btn-primary btn-lg btn-block mt-4" onClick={submitReviews}>
          Submit
            </button>}
        {submitting && <button className="btn btn-primary btn-lg btn-block mt-4" disabled="disabled">
          Submitting ...
            </button>}
      </div>
    </IndexLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        homepageDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          fluid(quality: 100, maxWidth:600) {
            src
          }
        }
      }
    }
  }
`

export default WithLocation(Review)
